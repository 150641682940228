export const subscriptionPlans = [
  {
    planId: "FREE_PLAN_01",
    priceId: "",
    title: "無料プラン",
    maxNoPdf: 0,
    maxFileSizeMB: 0, // unit MB
    maxMessagePerMonth: 50,
    price: 0,
    durationInDays: 15,
    maxAllowedDomain: 0, // each chatbot
    description: [
      "15日間無料でお試し",
      "個人使用向け",
      "クレジットカード不要",
      "高度なAIモデル",
      "高速・高性能な応答",
      "日々の業務の効率化",
    ],
    buttonVariant: "outlined",
  },
  {
    planId: "BASIC_PLAN_01",
    priceId: "price_1QbKB7P3zz1rfd7rXviQBtIE", //development: "price_1Ps1POP3zz1rfd7r08hGThzI",
    title: "ベーシックプラン",
    subheader: "",
    maxNoPdf: 0,
    maxFileSizeMB: 0, // Unit MB
    maxMessagePerMonth: 3000,
    price: "980", // Price in japanese yen
    durationInDays: 30,
    maxAllowedDomain: 0, // each chatbot
    description: [
      "最大75%のサポート費用割引",
      "プライベートかつ安全なデータ管理",
      "高度なAIモデル",
      "月間 3,000 件の問い合わせ件数",
      "高性能かつ高速な応答",
      "日々の業務の効率化",
    ],
    buttonVariant: "contained",
  },
  {
    planId: "PREMIUM_PLAN_01",
    priceId: "price_1PuUiPP3zz1rfd7rzwbfxxxy", //development: "price_1Ps1ZIP3zz1rfd7rMb4s5Da1",
    title: "プレミアムプラン",
    subheader: "",
    maxNoPdf: 3,
    maxFileSizeMB: 5, // Unit MB
    maxMessagePerMonth: 10000,
    price: "19,800", // Price in japanese yen
    durationInDays: 30,
    maxAllowedDomain: 1, // each chatbot
    description: [
      "AI による効率化でコストを 50% 削減",
      "プライベートかつ安全にチャットボットを作成",
      "ウェブサイトへの埋め込み",
      "効果的なクエリの追跡・分析",
      "高性能 AI による迅速な応答を実現",
      "毎月最大10,000件の問い合わせが可能",
    ],
    buttonVariant: "contained",
  },
];
