// src/components/ChatBoxV2.jsx

import React, { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import ChatbotFileList from "../chatbox/sub/chatbot-file-list";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  TextField,
  Paper,
  CircularProgress,
  List,
  ListItem,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { styled } from "@mui/material/styles";
import axiosInstance from "../../../api/axiosInstance";
import { enqueueSnackbar } from "notistack";
import PulseLoader from "react-spinners/PulseLoader";

// Styled Components
const ChatContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "85vh",
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: "8px",
  backgroundColor: theme.palette.background.default,
}));

const ChatHeader = styled(AppBar)(({ theme }) => ({
  position: "static",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
}));

const MessagesContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
  overflowY: "auto",
  backgroundColor: theme.palette.background.paper,
}));

const MessageItemBox = styled(Paper)(({ theme, sender }) => ({
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
  maxWidth: "70%",
  alignSelf: sender === "ai" ? "flex-start" : "flex-end",
  backgroundColor: sender === "ai" ? "#ebebeb" : "#1976d2",
  color: sender === "ai" ? "black" : theme.palette.primary.contrastText,
  borderRadius: "16px",
  borderTopLeftRadius: sender === "ai" ? "4px" : "16px",
  borderTopRightRadius: sender === "user" ? "4px" : "16px",
}));

const InputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
}));

const TypingIndicatorBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const MessageItem = ({ message }) => (
  <MessageItemBox sender={message.sender}>
    {message.sender === "ai" ? (
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{ __html: message.text }}
      />
    ) : (
      <Typography variant="body1">{message.text}</Typography>
    )}
  </MessageItemBox>
);
const MessageList = ({ messages }) => {
  const messagesEndRef = useRef(null);

  // Scroll to bottom when messages change
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <MessagesContainer>
      <List>
        {messages.map((msg, index) => (
          <ListItem
            key={index}
            disableGutters
            sx={{
              display: "flex",
              justifyContent: msg.sender === "user" ? "flex-end" : "flex-start",
            }}
          >
            <MessageItem message={msg} />
          </ListItem>
        ))}
        <div ref={messagesEndRef} />
      </List>
    </MessagesContainer>
  );
};

const MessageInput = ({ onSend, isLoading }) => {
  const [input, setInput] = useState("");

  const handleSend = () => {
    if (input.trim()) {
      onSend(input.trim());
      setInput("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !isLoading) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <InputContainer>
      <TextField
        variant="outlined"
        placeholder="Type your message..."
        fullWidth
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={handleKeyPress}
        disabled={isLoading}
      />
      <IconButton
        color="primary"
        onClick={handleSend}
        disabled={isLoading || input.trim() === ""}
        sx={{ marginLeft: 1 }}
      >
        {isLoading ? <CircularProgress size={24} /> : <SendIcon />}
      </IconButton>
    </InputContainer>
  );
};

const TypingIndicator = ({ isTyping }) => {
  if (!isTyping) return null;

  return (
    <TypingIndicatorBox>
      <PulseLoader size={8} color="#1976d2" />
      <Typography variant="caption" sx={{ marginLeft: 1 }}>
        AI is typing...
      </Typography>
    </TypingIndicatorBox>
  );
};

// Main ChatBoxV2 Component

const ChatBoxV2 = ({ chatbot }) => {
  const [messages, setMessages] = useState([
    {
      sender: "ai",
      text: "こんにちは、NCC AI チャットボットです。",
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [chatLimits, setChatLimits] = useState({
    maxNoChatMontly: chatbot?.maxNoChatMontly,
    remainingNoChatMontly: chatbot?.remainingNoChatMontly,
  });

  const handleSendMessage = async (userMessage) => {
    setMessages((prev) => [...prev, { sender: "user", text: userMessage }]);
    setIsLoading(true);
    setIsTyping(true);

    if (userMessage.length >= 200) {
      enqueueSnackbar("Message is too long", { variant: "warning" });
      setIsTyping(false);
      setIsLoading(false);
      return;
    }

    try {
      // Extract the last 10 user questions only, excluding the current userMessage
      const lastThreeUserQuestions = messages
        .filter((msg) => msg.sender === "user") // Only user messages
        .slice(-10) // Get the last 10 user questions
        .map((msg) => msg.text); // Extract the text content

      // Prepare the payload for the API without the current user message
      const payload = {
        message: userMessage, // Send the current question separately
        chatbotId: chatbot._id,
        history: lastThreeUserQuestions, // History excludes current message
      };

      // Send the payload to the backend
      const response = await axiosInstance.post(`/user/chatbot/query`, payload);
      const { maxNoChatMontly, remainingNoChatMontly, text } = response.data;

      // Format the response text for HTML rendering
      const formattedText = text
        .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>") // Bold text
        .replace(/\n\n/g, "</p><p>") // Replace double newlines with paragraphs
        .replace(/\n/g, "<br />"); // Replace single newlines with line breaks

      setChatLimits({ maxNoChatMontly, remainingNoChatMontly });
      const botResponse = {
        sender: "ai",
        text: formattedText || response.data.message,
      };

      setMessages((prev) => [...prev, botResponse]);
    } catch (error) {
      enqueueSnackbar(
        error?.response?.data?.error ||
          error?.response?.data ||
          "Error occurred",
        { variant: "error" }
      );
    } finally {
      setIsTyping(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (chatbot) {
      setChatLimits({
        maxNoChatMontly: chatbot.maxNoChatMontly,
        remainingNoChatMontly: chatbot.remainingNoChatMontly,
      });
    }
  }, [chatbot]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={9}>
        <ChatContainer>
          <MessageList messages={messages} />
          <TypingIndicator isTyping={isTyping} />
          <MessageInput onSend={handleSendMessage} isLoading={isLoading} />
          <Box p={2}>
            <Typography variant="caption">
              Remaining Chats: {chatLimits.remainingNoChatMontly}/
              {chatLimits.maxNoChatMontly}
            </Typography>
          </Box>
        </ChatContainer>
      </Grid>

      <Grid item xs={12} md={3}>
        <ChatbotFileList chatbotId={chatbot._id} />
      </Grid>
    </Grid>
  );
};

export default ChatBoxV2;
