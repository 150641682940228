import * as React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CurrencyYenIcon from "@mui/icons-material/CurrencyYen";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { subscriptionPlans } from "../../constants/subscriptionPlan";
import axiosInstance from "../../api/axiosInstance";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import SimpleBackdrop from "../loader/simple-backdrop";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSubscriptionDetails,
  fetchChatbotList,
} from "../../features/chatbot/chatbotSlice";
import AnimatedTitle from "./AnimatedTitle";

export default function Pricing() {
  const theme = useTheme(); // Access the current theme

  // Determine the color based on the theme mode
  const spanColor =
    theme.palette.mode === "light" ? "black" : theme.palette.primary.light;

  const { subscriptionDetail } = useSelector(
    (state) => state.chatbot.subscriptionDetails
  );
  const dispatch = useDispatch();
  const [subscription, setSubscription] = React.useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const isDisableSubsButton = (tier) => {
    if (tier.planId === "FREE_PLAN_01") {
      if (subscription?.noOfFreePlanUsed >= 1) return true;
    }
    if (
      tier.planId === subscription?.planId &&
      new Date(subscription?.expireDate) >= new Date()
    ) {
      return true;
    }
    // (tier.planId === subscription?.planId &&
    //   new Date(subscription?.expireDate) >= new Date()) ||
    // (tier.planId === "FREE_PLAN_01" && isFreePlanDisabled())
    return false;
  };

  const handleSubscribe = async ({ planId, priceId }) => {
    setLoading(true);
    try {
      if (planId === "FREE_PLAN_01") {
        await axiosInstance.post("/user/subscription/free-plan", {
          planId,
        });
        enqueueSnackbar("Thanks for subscribing us.", { variant: "success" });
        dispatch(fetchChatbotList());
        dispatch(fetchSubscriptionDetails());
        navigate("/chat");
      } else {
        const response = await axiosInstance.post(
          "/payment/create-checkout-session",
          {
            planId,
            priceId,
          }
        );
        window.location.href = `${response.data.url}`;
      }
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.error || error.response?.data, {
        variant: "error",
      });
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (subscriptionDetail) {
      const { subscription, noOfFreePlanUsed } =
        subscriptionDetail.subscription.user;
      setSubscription({ ...subscription, noOfFreePlanUsed });
    }
  }, [subscriptionDetail]);

  return (
    <Container
      id="pricing"
      sx={{
        pt: { sm: 12, xs: 12 },
        pb: { xs: 8, sm: 8 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      {loading && <SimpleBackdrop />}
      <Box sx={{ mt: { md: 4, xs: 2 }, width: "100%", margin: "auto" }}>
        <AnimatedTitle />
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {subscriptionPlans.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === "Enterprise" ? 12 : 6}
            md={4}
          >
            <Card
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                gap: 4,
                border:
                  tier.planId === "BASIC_PLAN_01" ? "1px solid" : undefined,
                borderColor:
                  tier.planId === "BASIC_PLAN_01" ? "primary.main" : undefined,
                background:
                  tier.planId === "BASIC_PLAN_01"
                    ? "linear-gradient(#033363, #021F3B)"
                    : undefined,
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    color: tier.planId === "BASIC_PLAN_01" ? "grey.100" : "",
                  }}
                >
                  {tier.planId === subscription?.planId &&
                    new Date(subscription?.expireDate) >= new Date() && (
                      <Chip
                        sx={{ transform: "rotate(-45deg)", mt: -2 }}
                        label="Active"
                        color="success"
                      />
                    )}
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {tier.planId === "BASIC_PLAN_01" && (
                    <Chip
                      icon={<AutoAwesomeIcon />}
                      label={tier.subheader}
                      size="small"
                      sx={{
                        background: (theme) =>
                          theme.palette.mode === "light" ? "" : "none",
                        backgroundColor: "primary.contrastText",
                        "& .MuiChip-label": {
                          color: "primary.dark",
                        },
                        "& .MuiChip-icon": {
                          color: "primary.dark",
                        },
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    color:
                      tier.planId === "BASIC_PLAN_01" ? "grey.50" : undefined,
                  }}
                >
                  <Typography component="h3" variant="h2">
                    <CurrencyYenIcon />
                    {tier.price}
                  </Typography>
                  <Typography component="h3" variant="h6">
                    &nbsp; / 月
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: "grey.500",
                  }}
                />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: "flex",
                      gap: 1.5,
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color:
                          tier.planId === "BASIC_PLAN_01"
                            ? "primary.light"
                            : "primary.main",
                      }}
                    />
                    <Typography
                      component="text"
                      variant="subtitle2"
                      sx={{
                        color:
                          tier.planId === "BASIC_PLAN_01"
                            ? "grey.200"
                            : undefined,
                      }}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant}
                  component="a"
                  onClick={() =>
                    handleSubscribe({
                      planId: tier.planId,
                      priceId: tier.priceId,
                    })
                  }
                  target="_blank"
                  disabled={isDisableSubsButton(tier)}
                >
                  {tier.buttonText || "サブスクリプション"}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
