import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const GradientButton = styled(Button)(({ theme }) => ({
  color: "whitesmoke",
  width: "100%",
  background: "linear-gradient(45deg, #9C27B0, #673AB7)", // Gradient color
  borderRadius: "30px", // Rounded corners
  padding: "7px 20px", // Add padding for better appearance
  fontWeight: "bold",
  textTransform: "none", // Disable uppercase text
  fontSize: "0.8rem", // Increase font size
  boxShadow: "0 3px 5px 2px rgba(156, 39, 176, 0.3)", // Subtle shadow for a premium look
  "&:hover": {
    background: "linear-gradient(45deg, #7B1FA2, #512DA8)", // Darker gradient on hover
    boxShadow: "0 4px 6px 2px rgba(123, 31, 162, 0.3)", // Slightly stronger shadow
  },
}));

export default function SubscriptionButton() {
  const navigate = useNavigate();
  return (
    <GradientButton onClick={() => navigate("/price")} variant="contained">
      サブスクリプションの購入
    </GradientButton>
  );
}
