import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

export default function AnimatedTitle({
  animatedTexts = [
    "最大75%のサポート費用割引",
    "個人向けにカスタマイズされたチャットボットを作成",
    "高度な AI モデル",
    "高性能 AI による迅速な応答を実現",
    "日々の業務の効率化",
    "ウェブサイトへの埋め込み",
  ],
}) {
  const theme = useTheme(); // Access the current theme
  const spanColor =
    theme.palette.mode === "light" ? "black" : theme.palette.primary.light;

  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [speed, setSpeed] = useState(100); // Typing speed

  useEffect(() => {
    const typeEffect = () => {
      const fullText = animatedTexts[currentIndex];
      setCurrentText(
        (prevText) =>
          isDeleting
            ? prevText.substring(0, prevText.length - 1) // Remove characters one by one
            : fullText.substring(0, prevText.length + 1) // Add characters one by one
      );

      // When typing is complete
      if (!isDeleting && currentText === fullText) {
        setTimeout(() => setIsDeleting(true), 1000); // Pause before deleting
      }

      // When deleting is complete
      if (isDeleting && currentText === "") {
        setIsDeleting(false); // Reset to typing mode
        setCurrentIndex((prevIndex) => (prevIndex + 1) % animatedTexts.length); // Move to the next text
      }

      // Adjust typing speed for typing vs deleting
      setSpeed(isDeleting ? 50 : 100);
    };

    const typingTimeout = setTimeout(typeEffect, speed);
    return () => clearTimeout(typingTimeout);
  }, [currentText, isDeleting, currentIndex, speed, animatedTexts]);

  return (
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      <Typography
        component="span"
        variant="h1"
        sx={{
          fontSize: "2rem",
          color: (theme) =>
            theme.palette.mode === "light" ? "primary.main" : "primary.light",
        }}
      >
        <span style={{ color: spanColor }}>{currentText}</span>
        <span style={{ color: spanColor, visibility: "visible" }}>|</span>
      </Typography>
    </Box>
  );
}
