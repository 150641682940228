import * as React from "react";
import {
  Box,
  List,
  ListItem,
  Avatar,
  Typography,
  IconButton,
  Tooltip,
  Stack,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import { format } from "date-fns"; // For date formatting
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../../api/axiosInstance";
import { useSnackbar } from "notistack";
import SimpleBackdrop from "../../../loader/simple-backdrop";
import ConfirmDialog from "../../../dialog/ConfirmDialog";
import {
  fetchChatbotList,
  fetchChatbotAndFileLimitDetails,
} from "../../../../features/chatbot/chatbotSlice";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.palette.divider,
  borderRadius: theme.spacing(1.5),
  marginBottom: theme.spacing(1),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "space-between",
}));

export default function AllFileList({ chatbotId }) {
  const dispatch = useDispatch();
  const { chatbot } = useSelector((state) => state.chatbot);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [pdfDocuments, setPdfDocuments] = React.useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [selectedFileId, setSelectedFileId] = React.useState("");

  const handleOpenFile = async ({ fileUrl }) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post("/user/chatbot/download-file", {
        fileUrl,
      });
      window.open(response.data, "_blank");
    } catch (error) {
      enqueueSnackbar(
        error?.response?.data?.error ||
          error.response?.message ||
          "Error occurred",
        { variant: "error" }
      );
    }
    setLoading(false);
  };

  const handleDeleteClick = ({ fileId }) => {
    setSelectedFileId(fileId);
    setConfirmDialogOpen(true);
  };

  const handleDeleteFile = async (fileId) => {
    setLoading(true);
    try {
      await axiosInstance.delete("/user/chatbot/delete-file", {
        data: { fileId, chatbotId },
      });

      enqueueSnackbar("File deleted successfully.", { variant: "success" });
      dispatch(fetchChatbotList());
      dispatch(fetchChatbotAndFileLimitDetails());
      setConfirmDialogOpen(false);
      setSelectedFileId("");
    } catch (error) {
      enqueueSnackbar(error?.response?.data || "Error occurred", {
        variant: "error",
      });
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (chatbot.chatbots) {
      const chatbotData = chatbot.chatbots.find((bot) => bot._id === chatbotId);
      if (chatbotData) {
        setPdfDocuments(chatbotData.pdfDocuments);
      }
    }
  }, [chatbot, chatbotId]);

  const fileListUI = ({ index, fileId, fileName, uploadedDate, fileUrl }) => (
    <StyledListItem key={index}>
      <Stack direction="row" spacing={2} alignItems="center" flex={1}>
        <ListItemAvatar>
          <Avatar alt="File" sx={{ bgcolor: "primary.main" }}>
            <FileCopyIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography
              variant="subtitle1"
              sx={{ wordBreak: "break-word", fontSize: "12px" }}
            >
              {fileName || "Untitled File"}
            </Typography>
          }
          secondary={
            <Typography
              sx={{ fontSize: "10px" }}
              variant="caption"
              color="textSecondary"
            >
              {format(new Date(uploadedDate), "dd MMM yyyy, hh:mm a")}
            </Typography>
          }
        />
      </Stack>
      <Stack direction="row" spacing={1}>
        <Tooltip title="Open File">
          <IconButton onClick={() => handleOpenFile({ fileUrl })}>
            <FileOpenIcon color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete File">
          <IconButton onClick={() => handleDeleteClick({ fileId })}>
            <DeleteIcon color="error" />
          </IconButton>
        </Tooltip>
      </Stack>
    </StyledListItem>
  );

  return (
    <>
      {loading && <SimpleBackdrop />}
      {confirmDialogOpen && (
        <ConfirmDialog
          open={confirmDialogOpen}
          handleClose={() => setConfirmDialogOpen(false)}
          handleConfirm={() => handleDeleteFile(selectedFileId)}
          title="ファイルを削除"
          content="このファイルを削除してもよろしいですか？この操作は元に戻せません。"
        />
      )}
      <Box
        sx={{
          bgcolor: "background.paper",
          maxHeight: "60vh",
          overflowY: "auto",
          padding: 2,
          borderRadius: 2,
          boxShadow: 2,
        }}
      >
        {pdfDocuments.length > 0 ? (
          <List>
            {pdfDocuments.map((item, index) =>
              fileListUI({
                index,
                fileId: item._id,
                fileName: item.fileName,
                uploadedDate: item.createdAt,
                fileUrl: item.srcUrl,
              })
            )}
          </List>
        ) : (
          <Typography variant="body2" color="textSecondary" align="center">
            "まだファイルがアップロードされていません。"
          </Typography>
        )}
      </Box>
    </>
  );
}
