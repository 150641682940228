import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Paper from "@mui/material/Paper";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import LanguageIcon from "@mui/icons-material/Language";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { IconButton, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import axiosInstance from "../../../../api/axiosInstance";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { fetchChatbotList } from "../../../../features/chatbot/chatbotSlice";
import DropZoneImageUploadModal from "../../../upload/drop-zone-image-upload";
import { publicBotUI } from "../../../../constants";

export default function ChatbotSettings({
  open,
  handleChangeModal,
  chatbotId,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { chatbot, subscriptionDetails } = useSelector(
    (state) => state.chatbot
  );
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [selectedLogo, setSelectedLogo] = useState("");
  const [name, setName] = useState(chatbot.name || "");
  const [description, setDescription] = useState(chatbot.description || "");
  const [domains, setDomains] = useState(chatbot.allowedDomains || [""]);
  const [isLocked, setIsLocked] = useState(chatbot.isLocked || false);
  const [maxAllowedDomain, setMaxAllowedDomain] = useState(null);
  const [existingLogo, setExistingLogo] = useState(false);
  const [existingLogoSrc, setExistingLogoSrc] = useState(null);

  useEffect(() => {
    if (chatbot.chatbots) {
      const chatbotData = chatbot.chatbots.find((bot) => bot._id === chatbotId);
      if (chatbotData) {
        setName(chatbotData.name);
        setDescription(chatbotData.description);
        if (chatbotData?.logo?.imgSrc) {
          setExistingLogoSrc(chatbotData?.logo?.imgSrc || "");
          setExistingLogo(true);
        }
        setDomains(
          chatbotData.allowedDomains.length > 0
            ? chatbotData.allowedDomains
            : [""]
        );
        setIsLocked(chatbotData.isLocked);
        setMaxAllowedDomain(chatbotData.maxAllowedDomain);
      }
    }
  }, [chatbot, chatbotId]);

  useEffect(() => {
    if (subscriptionDetails.subscriptionDetail) {
      setMaxAllowedDomain(
        subscriptionDetails.subscriptionDetail.subscription.plan
          .maxAllowedDomain
      );
    }
  }, [subscriptionDetails]);

  // Helper function to validate domain
  const isValidDomain = (domain) => {
    const regex =
      /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:[0-9]{1,5})?(\/[^\s]*)?$/;
    return regex.test(domain);
  };

  // Helper function to remove trailing slash
  const removeTrailingSlash = (domain) => {
    return domain.replace(/\/+$/, "");
  };

  const handleDeleteLogo = () => {
    handleClose();
    dispatch(fetchChatbotList());
  };
  const handleClose = () => {
    handleChangeModal(false);
  };

  const handleSaveChatbotLogo = async () => {
    try {
      if (!selectedLogo) return;

      const formData = new FormData(); // Correct capitalization
      formData.append("logo", selectedLogo);

      await axiosInstance.put(
        `/user/chatbot/upload-logo/${chatbotId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the correct content type
          },
        }
      );
      dispatch(fetchChatbotList());
    } catch (error) {
      enqueueSnackbar(
        error?.response?.data?.error || "Failed to update chatbot.",
        { variant: "error" }
      );
    }
  };

  const handleSave = async () => {
    setLoading(true);

    // Prepare domains by removing trailing slashes and validating them
    let newDomains = [];
    if (domains.length > 0) {
      let formattedDomains = [];
      for (const domain of domains) {
        const sanitizedDomain = removeTrailingSlash(domain);
        if (!isValidDomain(sanitizedDomain)) {
          enqueueSnackbar("Invalid domain format.", { variant: "error" });
          setLoading(false);
          return; // Stop the function if any domain is invalid
        }
        formattedDomains.push(sanitizedDomain);
      }
      newDomains = formattedDomains;
    }

    // Save logo first
    handleSaveChatbotLogo();

    // Save other chatbot settings
    try {
      await axiosInstance.put(`/user/chatbot/update/${chatbotId}`, {
        name,
        description,
        allowedDomains: newDomains,
        isLocked,
      });
      enqueueSnackbar("チャットボットが正常に更新されました。", {
        variant: "success",
      });
      handleClose();
      dispatch(fetchChatbotList());
    } catch (error) {
      enqueueSnackbar(
        error?.response?.data?.error || "チャットボットの更新に失敗しました。",
        { variant: "error" }
      );
    } finally {
      setLoading(false);
    }
  };

  const handleFileSelect = (file) => {
    setSelectedLogo(file);
    setExistingLogo(false);
  };

  const handleDomainChange = (index, value) => {
    const newDomains = [...domains];
    newDomains[index] = value;
    setDomains(newDomains);
  };

  const handleAddDomain = () => {
    if (domains.length < maxAllowedDomain) {
      setDomains([...domains, ""]);
    }
  };

  const handleRemoveDomain = (index) => {
    const newDomains = domains.filter((_, i) => i !== index);
    setDomains(newDomains);
  };

  const domainInputUI = (
    <Box sx={{ display: "flex", flexWrap: "wrap", flexDirection: "column" }}>
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        {maxAllowedDomain === 0
          ? "無料プランでは、ドメイン指定先への設置はできません。"
          : `最大${maxAllowedDomain}つのドメインを追加できます。`}
      </Typography>
      {domains.map((domain, index) => (
        <FormControl fullWidth sx={{ m: 1 }} key={index}>
          <InputLabel htmlFor={`outlined-adornment-domain-${index}`}>
            ドメイン
          </InputLabel>
          <OutlinedInput
            id={`outlined-adornment-domain-${index}`}
            value={domain}
            onChange={(e) => handleDomainChange(index, e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <LanguageIcon />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="delete"
                  onClick={() => handleRemoveDomain(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </InputAdornment>
            }
            label="Domain"
          />
        </FormControl>
      ))}
      {domains.length < maxAllowedDomain && (
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <IconButton variant="contained" onClick={handleAddDomain}>
            <AddCircleIcon />
          </IconButton>
        </Box>
      )}
      <Typography sx={{ fontWeight: 200, fontSize: "12px" }} variant="body2">
        例: https://yourdomain.com
      </Typography>
    </Box>
  );

  const copyScriptUI = (
    <Box sx={{ display: "flex", flexWrap: "wrap", mt: 4 }}>
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        以下のコードをコピーしてWebサイトへ貼り付けて下さい。
      </Typography>
      <Box
        component="div"
        sx={{
          width: "100%",
          backgroundColor: "black",
          color: "white",
          p: 2,
          borderRadius: 1,
          fontFamily: "monospace",
          overflowX: "auto",
          position: "relative",
        }}
      >
        <TextField
          value={
            subscriptionDetails?.subscriptionDetail?.subscription?.plan
              .planId === "PREMIUM_PLAN_01"
              ? `<script src="${publicBotUI}/chatbot-loader.js?chatbot=${chatbotId}"></script>`
              : `<script src="https://domain-chatbot.com/chatbot-loader.js?chatbot=${chatbotId}"></script>`
          }
          multiline
          fullWidth
          InputProps={{
            readOnly: true,
            disableUnderline: true,
            style: { color: "white", fontSize: "12px" },
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Copy">
                  <IconButton
                    sx={{
                      color: "white",
                      visibility: "hidden",
                      "&:hover": { visibility: "visible" },
                      position: "absolute",
                      right: 0,
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `<script src="${publicBotUI}/chatbot-loader.js?chatbot=${chatbotId}"></script>`
                      );
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          variant="filled"
          sx={{
            backgroundColor: "black",
            "& .MuiFilledInput-root": {
              backgroundColor: "black",
            },
            "& .MuiFilledInput-input": {
              color: "white",
            },
            "&:hover .MuiInputAdornment-root .MuiIconButton-root": {
              visibility: "visible",
            },
          }}
        />
      </Box>
    </Box>
  );

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Webサイトにチャットボットを設置"}
        </DialogTitle>
        <DialogContent>
          <Paper sx={{ p: { md: 4, xs: 2 } }} variant="outlined" square>
            <Box sx={{ mb: 1 }}>
              <Typography variant="subtitle1">
                チャットボットに使用するロゴをアップデート
              </Typography>
              <DropZoneImageUploadModal
                chatbotId={chatbotId}
                existingLogoSrc={existingLogoSrc}
                existingFile={existingLogo}
                file={selectedLogo}
                handleFileSelect={handleFileSelect}
                handleDeleteLogo={handleDeleteLogo}
              />
            </Box>
            {domainInputUI}
          </Paper>
          {copyScriptUI}
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: "black" }} autoFocus onClick={handleClose}>
            キャンセル
          </Button>
          <LoadingButton
            onClick={handleSave}
            loading={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
            sx={{ color: "black" }}
          >
            保存
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
